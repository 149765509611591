<template>
  <div style="background-color: rgba(50,50,120,0.03); height: 100%;">
    <div class="py-12">
      <div class="text-center py-2">
        <img size="30" src="@/assets/logo-deitada.png" style="width: 280px;" />
      </div>
    </div>
    <v-card class="pa-4 ma-8 mx-auto mt-5" max-width="500">
      <div>
        <div class="text-h4 pa-5 text-center primary--text">
          Recuperação de senha
        </div>
        <div v-if="errorToggle" class="px-5 py-2 red--text text-center">
          {{ errorMensagem }}
        </div>
        <div v-if="!chapter">
          <div class="font-weight-normal pa-5">
            Digite o email que você usou para se registrar, para que possamos
            recuperar a sua senha.
          </div>
          <v-form v-model="valid" class="px-5 mb-5">
            <v-text-field
              name="email"
              label="Email de uso"
              id="emailDeRecuperacao"
              v-model="email"
              :rules="validEmail"
            ></v-text-field>
          </v-form>
          <div class="text-center">
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="!valid"
              @click="enviarEmail()"
              >Recuperar senha</v-btn
            >
          </div>
        </div>
        <div v-if="chapter">
          <div class="px-5 mb-5">
            Pronto, enviamos um email para seu email para finalizar sua
            recuperação
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import authService from "@/middleware/userAuth.js";
export default {
  data() {
    return {
      loading: false,
      chapter: false,
      errorToggle: false,
      errorMensagem: "",
      dialog: true,
      email: "",
      valid: false,
      validEmail: [
        v => !!v || "Email é necessário",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email inválido"
      ]
    };
  },
  methods: {
    enviarEmail() {
      this.loading = true;
      authService
        .esqueciMinhaSenha(this.email)
        .then(() => {
          this.chapter = true;
          this.loading = false;
          this.errorToggle = false;
        })
        .catch(err => {
          this.loading = false;
          this.errorToggle = true;
          this.errorMensagem = err.data;
        });
    }
  }
};
</script>

<style></style>
